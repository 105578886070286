import shopifyEnterpriseGenericDefaults from '../shopify-enterprise-generic/config.js';

shopifyEnterpriseGenericDefaults.Widgets.find((w) => w.name === 'SearchVehicleWidget').columnBreakpoint = 850;
shopifyEnterpriseGenericDefaults.Widgets.find((w) => w.name === 'HeaderVehicleWidget').columnBreakpoint = 850;

window.Convermax.handleVerifyCollapsedContent = (selector) =>
  document.querySelector(`${selector}`)?.classList.toggle('cm_closed');

const searchResultCallback = () => {
  const tooltipButton = window.document.querySelector('.cm_tooltip-btn');
  const tooltipContent = window.document.querySelector('.cm_tooltip-content');

  if (tooltipContent && tooltipButton) {
    // cancel switching toggle to true state, and show tooltip when clicked,
    // is used for tablet/mobile
    tooltipButton.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      tooltipContent.classList.remove('cm_hide');
    });

    // show tooltip when hovered, is used for desktop
    tooltipButton.addEventListener('mouseover', () => {
      tooltipContent.classList.remove('cm_hide');
    });

    // hide tooltip when hover out, is used for desktop/tablet/mobile
    tooltipButton.addEventListener('mouseout', () => {
      tooltipContent.classList.add('cm_hide');
    });

    // hide tooltip when scrolling, is used for tablet/mobile
    window.document.body.addEventListener('touchmove', () => {
      tooltipContent.classList.add('cm_hide');
    });
  }
};

const addWishListItem = () => {
  document.querySelectorAll('#cm_results .wishlist-hero-custom-button').forEach((el) => {
    const id = el.getAttribute('data-wlh-id');
    const title = el.getAttribute('data-wlh-name');
    const price = el.getAttribute('data-wlh-price');
    const link = el.getAttribute('data-wlh-link');
    const variantId = el.getAttribute('data-wlh-variantid');
    const image = el.getAttribute('data-wlh-image');

    const productButtonInfo = {
      ButtonClassElement: `cm_wishlist-id--${id}`,
      ProductId: +id,
      ProductTitle: title,
      ProductPrice: +price,
      ProductLink: link,
      ProductVariantId: +variantId,
      ProductImage: image,
      ButtonMode: 'icon_only',
      WishlistHash: '',
    };

    window.WishListHero_SDK?.InitializeAddToWishListButton?.(productButtonInfo);
  });
};

const baseUpdateCallback = shopifyEnterpriseGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
).updateCallback;

const updateCallback = () => {
  baseUpdateCallback?.();
  searchResultCallback();

  // Wishlist Hero doesn't work on search page, only on collections grid
  // So use their JS API to init wishlist buttons
  // https://d2uzwr8l81w3zz.cloudfront.net/InitializeAddToWishListItem.html
  if (document.querySelector('#cm_results')) {
    addWishListItem();
  }
};

globalThis.Convermax.getB2CPrice = (item) => {
  const defaultPrice = item.price;
  const discountStartDate = new Date(item.b2c_start_date);
  const discountEndDate = new Date(item.b2c_end_date);
  const percentageOff = item.b2c_percentage_off;
  const now = Date.now();

  if (discountStartDate < now < discountEndDate) {
    return (defaultPrice * ((100 - percentageOff) / 100)).toFixed(2);
  }

  return defaultPrice;
};

export default {
  includes: ['shopify-enterprise-generic'],
  ...shopifyEnterpriseGenericDefaults,
  Widgets: [
    ...shopifyEnterpriseGenericDefaults.Widgets.filter(
      (w) => !['SearchResult', 'SearchHeader', 'VerifyFitment'].includes(w.name),
    ),
    {
      name: 'SearchHeader',
      sortSelectClass: 'cm_select__pretty',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
      facetToggleConfig: {
        toggledFacet: { field: 'Universal', term: 'True' },
        visibleIfFields: ['Year', 'Make', 'Model'],
      },
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { lastChildOf: '#cm-verify-fitment' },
      template: 'fitmentSearch/collapsibleVerifyFitment',
      isAlwaysColumnLayout: true,
      initCollapsed: true,
    },
  ],
};
